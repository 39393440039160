<template>
  <b-card>
    <b-row align-h="end">
      <b-col cols="2" class="my-1" align-self="end" style="text-align: end">
        <div
            class="d-flex align-items-center justify-content-end"
            align-self="end"
            style="text-align: end"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                class="d-inline-block"
                placeholder="Search"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <!-- <v-select v-model="filter" :options="statusOptions" class="invoice-filter-select" placeholder="Select Status">
                      <template #selected-option="{ label }">
                          <span class="text-truncate overflow-hidden">
                              {{ label }}
                          </span>
                      </template>
                  </v-select> -->
        </div>
      </b-col>
    </b-row>
    <b-table
        :busy="isBusy"
        responsive="sm"
        :fields="fields"
        :per-page="perPage"
        :items="quotes"
        :current-page="currentPage"
        :filter="filter"
        @filtered="onFiltered"
        @row-clicked="clickRow"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
          <strong> Loading...</strong>
        </div>
      </template>
      <template #cell(address)="data">
        {{ data.value + ", " + data.item.geography }}
      </template>
      <template #cell(rentable_sqft)="data">
        {{
          Number(data.value).toLocaleString(undefined, { minimumFractionDigits: 0 }) +
          " sqft"
        }}
      </template>
      <template #cell(updated_at)="data">
        <div :title="formatDatetime(data.item.updated_at)">
          {{ relativeTime(data.item.updated_at) }}
        </div>
      </template>

      <template #cell(sf_opportunity_status)="data">
        <b-badge
            pill
            variant="primary"
            :class="data.value.toLowerCase().split(' ').join('')"
        >
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex text-center text-primary">
          <div :id="'tooltip-eye' + data.item.sf_opportunity_number">
            <a
                @click="openEditOpportunityForm(data.item)"
            >
              <feather-icon icon="EditIcon" size="20" />
              <span class="align-middle ml-50">Edit</span>
            </a>
          </div>

          <b-tooltip
              :target="'tooltip-eye' + data.item.sf_opportunity_number"
              title="Edit Opportunity"
          />

          <!-- Dropdown -->
          <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              style="height: 0px; margin-top: -8px"
              :left="$store.state.appConfig.isRTL"
              dropleft
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                target="_blank"
                :href="`https://clearspace.lightning.force.com/lightning/r/Opportunity/${data.item.sf_opportunity_id}/view`"
            >
              <feather-icon icon="ExternalLinkIcon" />
              <span class="align-middle ml-50">Open SF Opp</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <b-row style="float: right">
      <b-col cols="2">
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
        />
      </b-col>
    </b-row>
    <b-modal
        v-model="showEditForm"
        title="Update Opportunity Details"
        size="lg"
        centered
        @close="getQuotes"
        @cancel="getQuotes"
    >
      <validation-observer ref="oppRules" tag="form">
        <b-row style="padding: 10px 15px">
          <b-col cols="3">
            <validation-provider
                #default="{ errors }"
                name="SF opportunity number"
                rules="required"
            >
              <label for="">SF opportunity number</label>
              <input
                  v-model="editForm.sf_opportunity_number"
                  class="form form-control"
                  type="text"
                  disabled
                  placeholder="Account name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="9">
            <validation-provider
                #default="{ errors }"
                name="Account name"
                rules="required"
            >
              <label for="">Account Name</label>
              <input
                  v-model="editForm.account_name"
                  class="form form-control"
                  type="text"
                  placeholder="Account name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <label for="">Address</label>
            <input
                v-model="editForm.address"
                type="text"
                class="form-control"
                placeholder="Address"
            />
          </b-col>
          <b-col cols="12">
            <validation-provider #default="{ errors }" name="Geography" rules="required">
              <label for="">Geography</label>
              <input
                  v-model="editForm.geography"
                  type="text"
                  class="form-control"
                  placeholder="Geography"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <label for="">Geography Sub Division</label>
            <input
                v-model="editForm.geography_subdivision"
                type="text"
                class="form-control"
                placeholder="Geography Sub Division"
            />
          </b-col>
          <b-col cols="6">
            <validation-provider
                #default="{ errors }"
                name="Rentable Sqft"
                :rules="{ required: true }"
            >
              <label for="">Rentable Sqft</label>
              <input
                  v-model="editForm.rentable_sqft"
                  type="text"
                  class="form-control"
                  placeholder="Rentable Sqft"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <label for="">Contact Name</label>
            <input
                v-model="editForm.contact_name"
                type="text"
                class="form-control"
                placeholder="Contact Name"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Contact Phone</label>
            <input
                v-model="editForm.account_phone"
                type="email"
                class="form-control"
                placeholder="Contact Phone"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Contact Email</label>
            <input
                v-model="editForm.account_email"
                type="text"
                class="form-control"
                placeholder="Contact Email"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Client Type</label>
            <select class="form-control" v-model="editForm.client_type">
              <option class="form-control" value="Tenant">Tenant</option>
              <option class="form-control" value="Landlord">Landlord</option>
            </select>
          </b-col>
          <b-col cols="6">
            <label for="">Salesforce Opportunity ID</label>
            <input
                v-model="editForm.sf_opportunity_id"
                type="text"
                disabled
                class="form-control"
                placeholder="Procore Uid"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Procore Uid</label>
            <input
                v-model="editForm.procore_uid"
                type="text"
                class="form-control"
                placeholder="Procore Uid"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Wrike Uid</label>
            <input
                v-model="editForm.wrike_uid"
                type="text"
                class="form-control"
                placeholder="Wrike Uid"
            />
          </b-col>
          <b-col cols="6">
            <label for="">QBO Uid</label>
            <input
                v-model="editForm.qbo_uid"
                type="text"
                class="form-control"
                placeholder="QBO Uid"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Sage Contract Id</label>
            <input
                v-model="editForm.sage_contract_id"
                type="text"
                class="form-control"
                placeholder="Sage Contract Id"
            />
          </b-col>
          <b-col cols="6">
            <label for="">Sage Job Id</label>
            <input
                v-model="editForm.sage_job_id"
                type="text"
                class="form-control"
                placeholder="Sage Job Id"
            />
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="updateOppDetails"
          >
            Update Opportunity Details
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BTable,
  BButton,
  BModal,
  VBModal,
  BRow,
  BCol,
  BSpinner,
  BBadge,
  BPagination,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BDropdown,
  BTooltip,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BButton,
    BModal,
    BRow,
    BCol,
    BBadge,
    BSpinner,
    BPagination,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BDropdown,
    BTooltip,
    BDropdownItem,
    moment,
    ValidationProvider,
    ValidationObserver,
    required,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      quotes: [],
      isBusy: true,
      filter: null,
      fields: [
        {
          key: "sf_opportunity_number",
          label: "#",
          sortable: true,
        },
        {
          key: "address",
          label: "PROJECT",
          sortable: false,
        },
        {
          key: "account_name",
          label: "CLIENT",
          sortable: true,
        },
        {
          key: "sf_opportunity_id",
          label: "Saleforce ID",
          sortable: true,
        },
        {
          key: "wrike_uid",
          label: "Wrike Id",
          sortable: true,
        },
        {
          key: "sage_contract_id",
          label: "Sage Contract Id",
          sortable: true,
        },
        {
          key: "procore_uid",
          label: "Procore Id",
          sortable: true,
        },
        {
          key: "qbo_uid",
          label: "QBO Id",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 1,
      statusOptions: [
        "Identified",
        "Quote Sent",
        "WON",
        "Quote Hardened",
        "Quote Revised",
      ],
      showEditForm: false,
      editForm: {
        id: null,
        account_name: null,
        address: null,
        client_type: null,
        contact_email: null,
        contact_name: null,
        contact_phone: null,
        geography: null,
        geography_subdivision: null,
        rentable_sqft: null,
        wrike_uid: null,
        procore_uid: null,
        qbo_uid: null,
        sf_opportunity_id: "",
        sage_contract_id: null,
        sage_job_id: null,
        sf_opportunity_number: null
      },
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  beforeMount() {
    this.getQuotes();
  },
  methods: {
    getQuotes() {
      this.$http
          .get("/opportunities/actions", {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.quotes = response.data;
            this.totalRows = this.quotes.length;
            //this.totalRows = this.quotes.length;
            this.isBusy = false;
          })
          .catch((error) => {
            this.showToast("danger", error.response.data.message);
          });
    },
    updateOppDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.oppRules.validate().then((success) => {
          if (success) {
            this.editForm.sf_opportunity_id = this.editForm.sf_opportunity_id;
            this.$http
                .put(`/opportunities/${this.editForm.sf_opportunity_id}`, this.editForm)
                .then((response) => {
                  //var activeQuoteRevision = this.opportunity.tfrs.find(n => (n.submitted_for_handover == true));
                  this.showToast("success", "Opportunity has been updated!", "Saved");
                  this.getQuotes();
                  this.showEditForm = false;
                  resolve(true);
                })
                .catch((error) => {
                  console.log(error);
                  this.showToast("danger", error);
                });
          } else {
            reject();
          }
        });
      });
    },
    clicked(item) {
      // console.log(item);
      const { sf_opportunity_id } = item;
      this.$router.push({
        path: `/opportunities/${sf_opportunity_id}`,
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    relativeTime(date) {
      return this.$dateF.formatDate(date);
    },
    formatDatetime(date) {
      return this.$dateF.formatDate(date);
    },
    clickRow(record, index) {
      this.clicked(record);
      // console.log(record);
    },
    openEditOpportunityForm(opp) {
      this.editForm = opp;
      this.showEditForm = true;
    },
  },
};
</script>

<style scoped>
.identified {
  background-color: #0d6efd1f;
  color: #0d6efd;
}
.quotesent {
  background-color: #6610f21f;
  color: #6610f2;
}
.quoterevised {
  background-color: #7367f01f;
  color: #7367f0;
}
.quotehardened {
  background-color: #d633841f;
  color: #d63384;
}
.approved {
  background-color: #00cfe81f;
  color: #00cfe8;
}
.won {
  background-color: #28c76f1f;
  color: #28c76f;
}
.lost {
  background-color: #6c757d1f;
  color: #4b4b4b;
}
.onhold {
  background-color: #ff9f431f;
  color: #ff9f43;
}
.invoice-filter-select {
  min-width: 190px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
